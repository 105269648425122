import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import { ButtonFilterOnBg } from "../../elements/Button/ButtonFilter"
import Button from "../../elements/Button"
import { StyledSelect as Select } from "../../elements/Select"

const Title = styled.p`
  color: ${props => props.theme.white};
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 1rem;
  text-align: center;
`

const SubTitle = styled(Title)`
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 0;
`

const Pricetext = styled.p`
  font-size: 22px;
  font-family: Rajdhani;
  margin-bottom: 1rem;
`

const WrapperButton = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`

const Item = styled.div`
  text-align: center;
  color: ${props => props.theme.white};
  padding: 1rem;
  margin-bottom: 6rem;
  /* &.item-offre-nantes {
  } */
`

const WrapperSlider = styled.div`
  /* .slick-list {
    overflow: unset;
  } */
`

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  background: ${props => props.theme.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Text = styled.p`
  max-width: 350px;
  margin: auto;
`
const Row = styled.div`
  max-width: 350px;
  margin: auto;
  margin-top: 1rem;
  text-align: left;
`

const HiddenButton = styled.button`
  display: none;
`;

const Price = ({
  tarifsNantes,
  offresNantesSolo,
  offresNantesDuo,
  tarifsDistance,
  uri
}) => {
  const [location, setLocation] = useState("nantes")
  const [offreSolo, setOffreSolo] = useState()
  const [offreDuo, setOffreDuo] = useState()

  const handleClick = value => {
    setLocation(value)
  }

  const updateSelectSolo = value => {
    setOffreSolo(value)
  }
  const updateSelectDuo = value => {
    setOffreDuo(value)
  }

  const getOffresNantesOptions = items => {
    let options = []
    items.map(item =>
      options.push({
        value: item.id_e_commerce.text,
        label: item.offre_selection.text,
      })
    )
    return options
  }

  const getOffresNantesSelected = (items, offreSelected) => {
    return items.filter(
      item => item.id_e_commerce.text === offreSelected.value
    )[0]
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: location === "distance" ? 3 : 2,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
        },
      },
    ],
  }
  return (
    <Section color="ternary" padding="80px 0 0">
      <Container>
        <Title>Mes Tarifs</Title>
        <WrapperButton>
          <ButtonFilterOnBg
            onClick={() => handleClick("nantes")}
            isActive={location === "nantes"}
          >
            À Nantes
          </ButtonFilterOnBg>
          <ButtonFilterOnBg
            onClick={() => handleClick("distance")}
            isActive={location === "distance"}
          >
            À Distance
          </ButtonFilterOnBg>
        </WrapperButton>
        <WrapperSlider>
          <Slider {...settings}>
            {location === "distance"
              ? tarifsDistance.map((item, index) => (
                  <Item key={index}>
                    <IconWrapper>
                      <Img fixed={item.icone.fixed} />
                    </IconWrapper>
                    <SubTitle>{item.titre.text}</SubTitle>
                    <Pricetext>{item.sous_titre.text}</Pricetext>
                    <Text>{item.contenu.text}</Text>
                    <Button
                      colors="primaryWithBg"
                      margin="2rem 0 0 0"
                      title="Acheter"
                      className="snipcart-add-item"
                      data-item-id={item.id_ecommerce.text}
                      data-item-name={item.titre.text}
                      data-item-price={item.prix.text}
                      data-item-url={uri}
                      data-item-description={item.description_ecommerce.text}
                      data-item-image={item.icone.url}
                    />
                  </Item>
                ))
              : tarifsNantes.map((item, index) => (
                  <Item key={index} className="item-offre-nantes">
                    <IconWrapper>
                      <Img fixed={item.icone.fixed} />
                    </IconWrapper>
                    <SubTitle>{item.titre.text}</SubTitle>
                    <Pricetext>{item.sous_titre.text}</Pricetext>
                    <Text>{item.contenu.text}</Text>
                    <Row>
                      <Select
                        dataSelect={
                          item.type === "Solo"
                            ? getOffresNantesOptions(offresNantesSolo)
                            : getOffresNantesOptions(offresNantesDuo)
                        }
                        placeholder="Choisir une offre"
                        value={item.type === "Solo" ? offreSolo : offreDuo}
                        handleChange={
                          item.type === "Solo"
                            ? value => updateSelectSolo(value)
                            : value => updateSelectDuo(value)
                        }
                        name={item.type === "Solo" ? "offre solo" : "offre duo"}
                        fullWidth
                        className="react-select"
                        isSearchable={false}
                      />
                    </Row>
                    <Button
                      colors="primaryWithBg"
                      margin="2rem 0 0 0"
                      title="Acheter"
                      className="snipcart-add-item"
                      data-item-id={
                        item.type === "Solo" && offreSolo
                          ? getOffresNantesSelected(offresNantesSolo, offreSolo)
                              .id_e_commerce.text
                          : offreDuo
                          ? getOffresNantesSelected(offresNantesDuo, offreDuo)
                              .id_e_commerce.text
                          : null
                      }
                      data-item-name={
                        item.type === "Solo" && offreSolo
                          ? getOffresNantesSelected(offresNantesSolo, offreSolo)
                              .offre_selection.text
                          : offreDuo
                          ? getOffresNantesSelected(offresNantesDuo, offreDuo)
                              .offre_selection.text
                          : null
                      }
                      data-item-price={
                        item.type === "Solo" && offreSolo
                          ? getOffresNantesSelected(offresNantesSolo, offreSolo)
                              .prix_e_commerce.text
                          : offreDuo
                          ? getOffresNantesSelected(offresNantesDuo, offreDuo)
                              .prix_e_commerce.text
                          : null
                      }
                      data-item-url={uri}
                      data-item-description={
                        item.type === "Solo" && offreSolo
                          ? getOffresNantesSelected(offresNantesSolo, offreSolo)
                              .description_e_commerce.text
                          : offreDuo
                          ? getOffresNantesSelected(offresNantesDuo, offreDuo)
                              .description_e_commerce.text
                          : null
                      }
                      data-item-image={item.icone.url}
                    />
                  </Item>
                ))}
          </Slider>
        </WrapperSlider>
        {
          offresNantesSolo.map((item, index) => (
            <HiddenButton
              key={index}
              colors="primaryWithBg"
              margin="2rem 0 0 0"
              title="Acheter"
              className="snipcart-add-item"
              data-item-id={item.id_e_commerce.text}
              data-item-name={item.offre_selection.text}
              data-item-price={item.prix_e_commerce.text}
              data-item-url={uri}
              data-item-description={item.description_e_commerce.text}
              data-item-image={tarifsNantes[0].icone.url}
            />
          ))
        }
        {
          offresNantesDuo.map((item, index) => (
            <HiddenButton
              key={index}
              colors="primaryWithBg"
              margin="2rem 0 0 0"
              title="Acheter"
              className="snipcart-add-item"
              data-item-id={item.id_e_commerce.text}
              data-item-name={item.offre_selection.text}
              data-item-price={item.prix_e_commerce.text}
              data-item-url={uri}
              data-item-description={item.description_e_commerce.text}
              data-item-image={tarifsNantes[1].icone.url}
            />
          ))
        }
        {
          tarifsDistance.map((item, index) => (
              <HiddenButton
                key={index}
                colors="primaryWithBg"
                margin="2rem 0 0 0"
                title="Acheter"
                className="snipcart-add-item"
                data-item-id={item.id_ecommerce.text}
                data-item-name={item.titre.text}
                data-item-price={item.prix.text}
                data-item-url={uri}
                data-item-description={item.description_ecommerce.text}
                data-item-image={item.icone.url}
              />
          ))
        }
      </Container>
    </Section>
  )
}

export default Price
