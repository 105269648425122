import React, { useState } from "react"
import { graphql } from "gatsby"
import { Location } from "@reach/router"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutContent from "../components/Commun/LayoutContent"
import Filter from "../components/Commun/LayoutContent/Filter"
import OffreCTA from "../components/Commun/LayoutContent/OffreCTA"
import Social from "../components/Commun/LayoutContent/Social"
import Banner from "../components/Commun/Banner"
import Price from "../components/Commun/Price"
import Temoignages from "../components/Commun/Temoignages/TemoignageNoImage"
import Contact from "../components/Commun/Contact"

const CoachingDetail = ({ data, location }) => {
  const prismicData = getPrismicData(data)
  const [gender, setGender] = useState(
    location && location.state && location.state.category
      ? location.state.category
      : "homme"
  )

  const handleClick = value => {
    setGender(value)
  }

  return (
    <Location>
      {({ location }) => (
        <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
          <SEO
            title={`Coaching ${prismicData.banner.titre}`}
            description="Je vous accompagne de A à Z durant toute la durée du suivi, en vous proposant un programme sportif et des conseils alimentaires adaptés à votre objectif."
          />
          <Banner
            title={`Coaching ${prismicData.banner.titre}`}
            imageBanner={
              gender === "homme"
                ? data.coachingDetailsImage.childImageSharp.fluid
                : data.coachingDetailsFemmeImage.childImageSharp.fluid
            }
            imageHeight="350px"
            imageMobileHeight="350px"
            isfirstBlock
            // objectPosition="center 0% !important"
            marginBottom="0"
          />
          <LayoutContent
            noButton
            contenuPrincipalHomme={prismicData.contenuPrincipalHomme}
            contenuPrincipalFemme={prismicData.contenuPrincipalFemme}
            handleClick={handleClick}
            gender={gender}
            objectPosition="center 30% !important"
          >
            <Filter
              location={location}
              items={prismicData.filter.items.reverse()}
              prefix="/coaching-sportif-personnalise/"
              gender={gender}
            />
            {/* <OffreCTA
              titre="Mes E-books"
              text="Accedat huc suavitas quaedam oportet sermonum atque morum, haudquaquam mediocre condimentum"
              // buttonText="En savoir plus"
              // buttonUrl="/e-books"
            /> */}
            <Social />
          </LayoutContent>
          <Price
            balance={data.balance.childImageSharp.fixed}
            tarifsNantes={prismicData.tarifsNantes.tarifs}
            offresNantesSolo={prismicData.offresNantesSolo.items}
            offresNantesDuo={prismicData.offresNantesDuo.items}
            tarifsDistance={prismicData.tarifsDistance.tarifs}
            uri={location.href}
          />
          <Temoignages
            items={
              gender === "homme"
                ? prismicData.temoignagnesHomme.items
                : prismicData.temoignagnesFemme.items
            }
          />
          <Contact />
        </Layout>
      )}
    </Location>
  )
}

export default CoachingDetail

export const query = graphql`
  query($uid: String!) {
    prismicCoachingDetail(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicCoachingDetailBodyBanniere {
            primary {
              titre {
                text
                html
              }
            }
          }
          ... on PrismicCoachingDetailBodyContenuPrincipalFemme {
            primary {
              titre {
                text
                html
              }
              contenu {
                html
                text
              }
              image {
                fluid(maxWidth: 3000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicCoachingDetailBodyContenuPrincipalHomme {
            id
            primary {
              contenu {
                html
                text
              }
              titre {
                html
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicCoachingDetailBodyTemoignage {
            items {
              contenu {
                html
                text
              }
              nom {
                html
                text
              }
            }
          }
          ... on PrismicCoachingDetailBodyTemoignageFemme {
            items {
              contenu {
                html
                text
              }
              nom {
                html
                text
              }
            }
          }
        }
      }
    }
    prismicCoachingPourHommeBodyTarifs {
      items {
        type
        contenu {
          html
          text
        }
        sous_titre {
          text
        }
        titre {
          html
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantes {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantesSolo {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyTarifsDistance {
      items {
        contenu {
          html
          text
        }
        prix {
          html
          text
        }
        sous_titre {
          html
          text
        }
        titre {
          html
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
        description_ecommerce {
          text
        }
        id_ecommerce {
          text
        }
      }
    }
    allPrismicCoachingDetail {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicCoachingDetailBodyBanniere {
                primary {
                  titre {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingDetailsImage: file(
      relativePath: { eq: "detail-coaching-homme.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coachingDetailsFemmeImage: file(relativePath: { eq: "detail-coaching-femme.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    balance: file(relativePath: { eq: "icones/scale.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicCoachingDetail.data.body[0].primary.titre.text,
    },
    filter: {
      items: [
        {
          url: data.allPrismicCoachingDetail.edges[0].node.uid,
          titre:
            data.allPrismicCoachingDetail.edges[0].node.data.body[0].primary
              .titre,
        },
        {
          url: data.allPrismicCoachingDetail.edges[1].node.uid,
          titre:
            data.allPrismicCoachingDetail.edges[1].node.data.body[0].primary
              .titre,
        },
        {
          url: data.allPrismicCoachingDetail.edges[2].node.uid,
          titre:
            data.allPrismicCoachingDetail.edges[2].node.data.body[0].primary
              .titre,
        },
        {
          url: data.allPrismicCoachingDetail.edges[3].node.uid,
          titre:
            data.allPrismicCoachingDetail.edges[3].node.data.body[0].primary
              .titre,
        },
      ],
    },
    contenuPrincipalHomme: {
      titre: data.prismicCoachingDetail.data.body[1].primary.titre.text,
      contenu: data.prismicCoachingDetail.data.body[1].primary.contenu.html,
      image:
        data.prismicCoachingDetail.data.body[1].primary.image.fluid,
    },
    contenuPrincipalFemme: {
      titre: data.prismicCoachingDetail.data.body[2].primary.titre.text,
      contenu: data.prismicCoachingDetail.data.body[2].primary.contenu.html,
      image:
        data.prismicCoachingDetail.data.body[2].primary.image.fluid,
    },
    tarifsNantes: {
      tarifs: data.prismicCoachingPourHommeBodyTarifs.items,
    },
    offresNantesSolo: {
      items: data.prismicCoachingPourHommeBodyOffresNantes.items,
    },
    offresNantesDuo: {
      items: data.prismicCoachingPourHommeBodyOffresNantesSolo.items,
    },
    tarifsDistance: {
      tarifs: data.prismicCoachingPourHommeBodyTarifsDistance.items,
    },
    temoignagnesHomme: {
      items: data.prismicCoachingDetail.data.body[3].items,
    },
    temoignagnesFemme: {
      items: data.prismicCoachingDetail.data.body[4].items,
    },
  }
}
